import React from 'react';
import '../css/Topbar.css';

const Topbar = () => {
  return (
    <div className="topbar">
      <div className="topbar-content">
        <img
          src="https://cargoes-platform-prod-images-cdn.azureedge.net/cargoes-prod-images/assets/images/Cargoes_logo_RGB_Positive.svg"
          alt="Logo"
          className="logo"
        />
      </div>
    </div>
  );
};

export default Topbar;
