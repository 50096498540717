import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, Paper, Button, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import notify from '../../utils/notifier';
import { manageColumns as locationsManageColumns } from './Locations/Locations';
import { columns as locationsColumns } from './Locations/Locations';
import { Locations } from './Locations/Locations';
import { Addresses, addressManageColumns, addressColumns } from './Addresses/Addresses';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from '../../styles/ManageLocationsStyles';
import PaginationComponent from '../PaginationComponent';
import ManageColumnsDialog from '../ManageColumnsDialog';
import SearchBar from '../SearchBarComponent';
import TableComponent from '../TableComponent';
import FilterDialog from '../FilterDialog';

const tabMapping = {
  zones: 0,
  locations: 1,
  addresses: 2,
  nodes: 3
};

const reverseTabMapping = Object.keys(tabMapping).reduce(
  (obj, key) => ({ ...obj, [tabMapping[key]]: key }),
  {}
);

const _columns = {
  locations: locationsColumns,
  addresses: addressColumns,
  zones: [],
  nodes: []
};

const _manageColumns = {
  locations: locationsManageColumns,
  addresses: addressManageColumns,
  zones: {},
  nodes: {}
};

const ManageLocations = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(1);
  const [page, setPage] = useState(0);
  const [rows, setRows] = React.useState([]);
  const [filters, setFilters] = useState({});
  const [originalRows, setOriginalRows] = React.useState([]);
  const [originalQueryCount, setOriginalQueryCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [circularBackdrop, setCircularBackdrop] = useState(false);
  const [openManageColumnsDialog, setOpenManageColumnsDialog] = useState(false);
  const [queryCount, setQueryCount] = useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('place_name');
  const [columns, setColumns] = React.useState(_columns['locations']);
  const [manageColumns, setManageColumns] = useState(_manageColumns['locations']);
  const [searchKey, setSearchKey] = useState({ value: '' });

  const {
    fetchLocationsData,
    handleApplyLocationFilters,
    renderLocationsFilters,
    handleLocationsFilterClear
  } = Locations();

  const {
    fetchAddressesData,
    renderAddressesFilters,
    handleApplyAddressFilters,
    handleAddressessFilterClear
  } = Addresses();

  const _fetchData = {
    locations: fetchLocationsData,
    addresses: fetchAddressesData,
    zones: () => Promise.resolve({ data: [] }),
    nodes: () => Promise.resolve({ data: [] })
  };

  const _applyFilter = {
    locations: handleApplyLocationFilters,
    addresses: handleApplyAddressFilters,
    zones: () => Promise.resolve({ data: [] }),
    nodes: () => Promise.resolve({ data: [] })
  };

  const _renderFilters = {
    locations: () => <>{renderLocationsFilters()}</>,
    addresses: () => <>{renderAddressesFilters()}</>,
    zones: () => <div>No Filters for Zones</div>, // Placeholder if no filters
    nodes: () => <div>No Filters for Nodes</div>
  };

  const _clearFilter = {
    locations: handleLocationsFilterClear,
    addresses: handleAddressessFilterClear,
    zones: () => Promise.resolve({ data: [] }),
    nodes: () => Promise.resolve({ data: [] })
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab'); // Get the tab query parameter
    if (tab && tabMapping[tab] !== undefined) {
      setSelectedTab(tabMapping[tab]); // Set the selected tab based on the query parameter
    }
    fetchData(tabMapping[tab], rowsPerPage, 0, orderBy, order, searchKey.value, filters);
  }, [location.search]);

  const handleSearch = event => {
    const _val = event.target.value;
    setSearchKey(prevState => {
      return { ...prevState, value: _val };
    });
    if (_val.length <= 2) {
      setRows(originalRows);
      setQueryCount(originalQueryCount);
      return;
    }
    fetchData(selectedTab, rowsPerPage, 0, orderBy, order, _val);
  };

  const handleNavigation = tab => {
    const tabName = reverseTabMapping[tab];
    navigate(`/locations?tab=${tabName}`); // Pass the selected tab as a query parameter
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setManageColumns(_manageColumns[reverseTabMapping[newValue]]);
    handleNavigation(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(selectedTab, rowsPerPage, newPage, orderBy, order, searchKey.value, filters);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    fetchData(selectedTab, +event.target.value, 0, orderBy, order, searchKey.value, filters);
  };

  const handleFilterButtonClick = () => {
    setOpenFilterDialog(true); // Open filter modal when the filter button is clicked
  };

  const handleFilterClose = () => {
    setOpenFilterDialog(false);
  };

  // Handle Apply filter
  const handleApplyFilters = appliedFilters => {
    setFilters(appliedFilters);
    setPage(0);
    setOpenFilterDialog(false);
    setCircularBackdrop(true);
    _applyFilter[reverseTabMapping[selectedTab]](filters, setFilters)
      .then(response => {
        let _data = response.data;
        setRows(_data);
        if (response.hasOwnProperty('x-total-count')) {
          setQueryCount(parseInt(response['x-total-count']));
        }
        setCircularBackdrop(false);
      })
      .catch(error => {
        setCircularBackdrop(false);
        notify({
          type: 'error',
          message: 'Internal Server Error'
        });
      });
  };

  const handleClearFilters = () => {
    setFilters({});
    _clearFilter[reverseTabMapping[selectedTab]]();
  };

  const handleToggleColumn = column => event => {
    setManageColumns({
      ...manageColumns,
      [column]: event.target.checked
    });
  };

  const handleManageColumnsOpen = () => {
    setOpenManageColumnsDialog(true);
  };

  const handleManageColumnsClose = () => {
    setOpenManageColumnsDialog(false);
  };

  const clearSearchText = () => {
    setSearchKey({ value: '' });
  };

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const handleActionClick = action => {
    if (action === 'View') {
      navigate(`/locations/${currentRow.code}`, { state: { location: currentRow } }); // Navigate to the view page
    }
    handleMenuClose();
  };

  const fetchData = (
    tab,
    pageSize = rowsPerPage,
    currentPage = 0,
    sortBy = orderBy,
    sortOrder = order,
    searchText = null,
    filterParams = filters
  ) => {
    setCircularBackdrop(true);
    let query = {
      _page: currentPage + 1,
      _limit: pageSize,
      _sort: sortBy,
      _order: sortOrder,
      ...filterParams
    };
    if (searchText) {
      query['q'] = searchText;
    }

    _fetchData[reverseTabMapping[tab]](query)
      .then(response => {
        let _data = response.data;
        setRows(_data);
        if (response.hasOwnProperty('x-total-count')) {
          setQueryCount(parseInt(response['x-total-count']));
        }
        setCircularBackdrop(false);
      })
      .catch(error => {
        setCircularBackdrop(false);
        notify({
          type: 'error',
          message: 'Internal Server Error'
        });
      });
  };

  return (
    <div style={{
      height: 'calc(100vh - 120px)', // Occupy 80% of the viewport height
      padding: '30px', // Padding for inner spacing
    }}>
      <div style={{marginBottom: '30px'}}>
        <Typography className={classes.headerText} variant="h6" component="h2">
              Manage Locations
        </Typography>
      </div>
      <div style={{padding:'20px', backgroundColor: 'white', color:'black'}}>
        <Backdrop open={circularBackdrop} style={{ color: 'primary', zIndex: 1000000 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={12}>
          <Tabs  value={selectedTab} onChange={handleTabChange} aria-label="location tabs">
            <Tab label="Zones"/>
            <Tab label="Locations" />
            <Tab label="Addresses" />
            <Tab label="Nodes" />
          </Tabs>
        </Grid>

        {/* Filter and Manage Columns */}
        <Grid container justifyContent="space-between" style={{ padding: '10px' }}>
          <div className={classes.tableHeadRight}>
            <SearchBar
              searchKey={searchKey}
              handleSearch={handleSearch}
              clearSearchText={clearSearchText}
              handleFilterButtonClick={handleFilterButtonClick}
            />
            <Button
              variant="outlined"
              style={{ marginLeft: '10px',marginTop: '10px'  }}
              startIcon={<FilterListIcon />}
              onClick={handleFilterButtonClick}
            >
              Filters
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: '10px',marginTop: '10px' }}
              onClick={handleManageColumnsOpen}
            >
              Manage Columns
            </Button>
          </div>
        </Grid>

        {/* Table */}
        <TableComponent
          rows={rows}
          columns={columns}
          manageColumns={manageColumns}
          handleMenuClick={handleMenuClick}
          circularBackdrop={circularBackdrop}
        />

        {/* Pagination */}
        <PaginationComponent
          queryCount={queryCount}
          page={page}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />

        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={() => handleActionClick('View')}>View</MenuItem>
          <MenuItem onClick={() => handleActionClick('Edit')}>Edit</MenuItem>
          <MenuItem onClick={() => handleActionClick('Archive')}>Archive</MenuItem>
        </Menu>

        <FilterDialog
          open={openFilterDialog}
          handleClose={handleFilterClose}
          handleApplyFilters={handleApplyFilters}
          renderFilters={_renderFilters[reverseTabMapping[selectedTab]]}
          handleFilterClear={handleClearFilters}
        />

        <ManageColumnsDialog
          open={openManageColumnsDialog}
          handleClose={handleManageColumnsClose}
          manageColumns={manageColumns}
          handleToggleColumn={handleToggleColumn}
        />
      </div>
    </div>
  );
};

export default ManageLocations;
