import React from 'react';
import '../css/HomePage.css';
import { useNavigate } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import useStyles from '../styles/HomePageStyles';

const HomePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleNavigation = tab => {
    navigate(`/locations?tab=${tab}`); // Pass the selected tab as a query parameter
  };

  return (
    <div className="content">
      <Paper elevation={0} className={classes.root}>
        <h2>
          Select the Type of location you would like to manage (View, Update & Archive) today.
        </h2>
        <div className="buttons">
          <button className="location-button" onClick={() => handleNavigation('zones')}>
            Zones
          </button>
          <button className="location-button" onClick={() => handleNavigation('locations')}>
            Locations
          </button>
          <button className="location-button" onClick={() => handleNavigation('addresses')}>
            Addresses
          </button>
          <button className="location-button" onClick={() => handleNavigation('nodes')}>
            Nodes
          </button>
        </div>
      </Paper>
    </div>
  );
};

export default HomePage;
