import React from 'react';
import '../css/Sidebar.css';
import { Link } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  IconButton,
  Divider
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import addressIcon from '../icons/Address.svg';
import homeIcon from '../icons/Home.svg';
import cargoesLogo from '../images/Cargoes.png';
import portIcon from '../icons/Port.svg';
import locationIcon from '../icons/LocationPin.svg';
import zoneIcon from '../icons/Zone.svg';
import { _routes } from '../utils/Routes';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles/SidebarStyles';

const icons = {
  Home: <img src={homeIcon} alt="Home" />,
  Locations: <img src={locationIcon} alt="Locations" />,
  Zones: <img src={zoneIcon} alt="Zones" />,
  Addresses: <img src={addressIcon} alt="Addresses" />,
  Nodes: <img src={portIcon} alt="Nodes" />
};

const currentTheme = { palette: { primary: { main: '#123456' } }, direction: 'ltr' };

const Sidebar = () => {
  const classes = useStyles();
  const [routes] = React.useState(_routes);
  const [t] = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  return (
    <div className={classes.drawerContainer}>
      <Drawer
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          {currentTheme.direction === 'rtl' ? (
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon />
            </IconButton>
          ) : (
            <div style={{ color: 'white' }}>
              <img src={cargoesLogo} alt="Logo" style={{ height: '1.2em', verticalAlign: 'sub' }} />
              &nbsp;<b>Locations SERVICE</b>
            </div>
          )}
        </div>
        <Divider />
        <List>
          {routes.map((route, index) => (
            <ListItem button key={route.title}>
              <Link to={route.route} className={classes.displayContents}>
                <ListItemIcon>{icons[route.title]}</ListItemIcon>
                {open && <ListItemText primary={t(route.title)} />}
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default Sidebar;
