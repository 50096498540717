import { useState, useEffect } from 'react';
import LocationsService from '../../../services/LocationsService';
import notify from '../../../utils/notifier';
import { Grid, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';

export const columns = [
  { id: 'id', label: 'Id',width: '10%'},
  { id: 'placeName', label: 'Place Name',width: '10%' },
  { id: 'locality', label: 'Locality',width: '10%' },
  { id: 'zipcode', label: 'Zip Code',width: '10%' },
  { id: 'stateId', label: 'State Id',width: '10%' },
  { id: 'latitude', label: 'Latitude',width: '10%' },
  { id: 'longitude', label: 'Longitude' ,width: '10%'},
  { id: 'isActive', label: 'Active',width: '10%' },
  { id: 'type', label: 'Type',width: '10%' },
  { id: 'locode', label: 'Locode' ,width: '10%'},
  { id: 'cityId', label: 'City Id' ,width: '10%'},
  { id: 'countryId', label: 'Country Id',width: '10%' },
  { id: 'actions', label: 'Actions',width: '10%' }
];

export const defaultFilters = {
  city: '',
  state: '',
  country: '',
  state_code: '',
  country_code: '',
  is_active: 'true',
  zipcode: ''
};

export const manageColumns = {
  id: true,
  placeName: true,
  locality: true,
  zipcode: true,
  stateId: true,
  cityId: false,
  latitude: false,
  longitude: false,
  locode: false,
  countryId: false,
  type: false
};

export const Locations = () => {
  const [locationsFilterValues, setLocationsFilterValues] = useState(defaultFilters);
  const [backdrop, setBackdrop] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateCodes, setStateCodes] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);

  // Fetch countries data on mount
  useEffect(() => {
    if (!countries.length) {
      getCountriesData();
    }
  }, [countries]);

  // Fetch states and cities when the country filter changes
  useEffect(() => {
    if (locationsFilterValues.country) {
      getStatesData(locationsFilterValues.country);
      getCitiesData(locationsFilterValues.country);
    }
  }, [locationsFilterValues.country, countries]);

  // Fetch cities when the state filter changes
  useEffect(() => {
    if (locationsFilterValues.state) {
      getCitiesData(locationsFilterValues.country, locationsFilterValues.state);
    }
  }, [locationsFilterValues.state, states]);

  // Function to clear the filters
  const handleLocationsFilterClear = () => {
    setStates([]);
    setCities([]);
    setStateCodes([]);
    setLocationsFilterValues(defaultFilters);
  };

  // Function to apply the filters
  const handleApplyLocationFilters = async (filters, setFilters) => {
    if (locationsFilterValues.city) {
      const city = cities.find(
        city =>
          city.name === locationsFilterValues.city &&
          city.countryIso2 === locationsFilterValues.country_code
      );
      filters['_cityId'] = city.id;
    } else if (locationsFilterValues.state) {
      const state = states.find(
        state =>
          state.name === locationsFilterValues.state &&
          state.countryIso2 === locationsFilterValues.country_code
      );
      filters['_stateId'] = state.id;
    } else if (locationsFilterValues.country) {
      const country = countries.find(
        country =>
          country.name === locationsFilterValues.country &&
          country.iso2 === locationsFilterValues.country_code
      );
      filters['_countryId'] = country.id;
    } else {
      filters['allLocations'] = true;
    }
    setFilters(filters);
    return await fetchLocationsData(filters);
  };

  // Function to fetch countries
  const getCountriesData = async () => {
    try {
      setBackdrop(true);
      const response = await LocationsService.getCountriesData();
      const countriesData = response.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      const countryCodesData = countriesData.map(x => x.iso2).sort();
      setCountries(countriesData);
      setCountryCodes(countryCodesData);
    } catch (error) {
      notify({ type: 'error', message: 'Error fetching countries data' });
    } finally {
      setBackdrop(false);
    }
  };

  // Function to fetch states based on country
  const getStatesData = async countryName => {
    try {
      const country = countries.find(country => country.name === countryName);
      const query = { _countryIso2: country.iso2 };
      setBackdrop(true);
      const response = await LocationsService.getStatesData(query);
      const statesData = response.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });;
    
      setStates(statesData);
      setStateCodes(statesData.map(x => x.stateIso2));
    } catch (error) {
      notify({ type: 'error', message: 'Error fetching states data' });
    } finally {
      setBackdrop(false);
    }
  };

  // Function to fetch cities based on country and state
  const getCitiesData = async (countryName, stateName = '') => {
    try {
      const country = countries.find(country => country.name === countryName);
      const query = { _countryIso2: country.iso2 };
      if (stateName) {
        const state = states.find(state => state.name === stateName);
        query['_stateIso2'] = state.stateIso2;
      }
      setBackdrop(true);
      const response = await LocationsService.getCitiesData(query);
      response.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });;
      setCities(response.data);
    } catch (error) {
      notify({ type: 'error', message: 'Error fetching cities data' });
    } finally {
      setBackdrop(false);
    }
  };

  const handleCountryFilterChange = event => {
    const { name, value } = event.target;
    const country = countries.find(country => country.name === value);
    setLocationsFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      country_code: country.iso2
    }));
    getStatesData(value);
    getCitiesData(value);
  };

  const handleStateFilterChange = event => {
    const { name, value } = event.target;
    const state = states.find(state => state.name === value);
    setLocationsFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      state_code: state.stateIso2 || ''
    }));
    if (!locationsFilterValues.city) {
      getCitiesData(locationsFilterValues.country, value);
    }
  };

  const handleCityFilterChange = event => {
    const { name, value } = event.target;
    if (!locationsFilterValues.state) {
      const selectedCity = cities.find(city => city.name === value);
      setLocationsFilterValues(prevValues => ({
        ...prevValues,
        [name]: value,
        state: selectedCity ? selectedCity.stateName : prevValues.state,
        state_code: selectedCity ? selectedCity.stateIso2 : prevValues.state_code,
        country: selectedCity ? selectedCity.countryName : prevValues.country,
        country_code: selectedCity ? selectedCity.countryIso2 : prevValues.country_code
      }));
    } else {
      setLocationsFilterValues(prevValues => ({
        ...prevValues,
        [name]: value
      }));
    }
  };

  const handleCountryCodeFilterChange = event => {
    const { name, value } = event.target;
    const country = countries.find(country => country.iso2 === value);
    setLocationsFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      country: country.name || ''
    }));
    getStatesData(country.name);
    getCitiesData(country.name);
  };

  const handleStateCodeFilterChange = event => {
    const { name, value } = event.target;
    const state = states.find(state => state.stateIso2 === value);
    setLocationsFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      state: state.name || ''
    }));
    getCitiesData(locationsFilterValues.country, state.name);
  };

  const handleActiveFilterChange = event => {
    const { name, value } = event.target;
    const parsedValue = value === 'true' ? true : false;

    setLocationsFilterValues(prevValues => ({
      ...prevValues,
      [name]: parsedValue
    }));
  };

  // Function to fetch location data
  const fetchLocationsData = async query => {
    let output = {};
    try {
      if (!(query['_cityId'] || query['_stateId'] || query['_countryId'])) {
        query['allLocations'] = true;
      } else {
        delete query.allLocations;
      }
      const response = await LocationsService.getLocationsData(query);
      output.data = response.data;
      if (response.headers.hasOwnProperty('x-total-count')) {
        output['x-total-count'] = parseInt(response.headers['x-total-count']);
      }
    } catch (error) {
      notify({
        type: 'error',
        message: 'Internal Server Error'
      });
    }
    return output;
  };

  const locationsFilterConfig = [
    {
      name: 'city',
      label: 'City',
      type: 'select',
      options: cities,
      filterChange: handleCityFilterChange
    },
    {
      name: 'state',
      label: 'State',
      type: 'select',
      options: states,
      filterChange: handleStateFilterChange
    },
    {
      name: 'country',
      label: 'Country',
      type: 'select',
      options: countries,
      filterChange: handleCountryFilterChange
    },
    {
      name: 'state_code',
      label: 'State Code',
      type: 'select',
      options: stateCodes,
      filterChange: handleStateCodeFilterChange
    },
    {
      name: 'country_code',
      label: 'Country Code',
      type: 'select',
      options: countryCodes,
      filterChange: handleCountryCodeFilterChange
    },
    {
      name: 'is_active',
      label: 'Active',
      type: 'select',
      options: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' }
      ],
      filterChange: handleActiveFilterChange
    }
  ];

  const renderLocationsFilters = () => {

    return locationsFilterConfig.map(filter => {
      const options = filter.options;
      const isCountrySelected =
        !!locationsFilterValues.country_code || !!locationsFilterValues.country;

      // Determine if the current filter should be disabled
      const isDisabled =
        (filter.name === 'state' || filter.name === 'state_code' || filter.name === 'city') &&
        !isCountrySelected;

      return (
        <Grid item xs={4} key={filter.name}>
          <FormControl fullWidth>
            <InputLabel>{filter.label}</InputLabel>
            <Select
              name={filter.name}
              value={locationsFilterValues[filter.name]}
              onChange={filter.filterChange}
              disabled={isDisabled}
            >
              {options.map(option => (
                <MenuItem
                  key={option.value || option.name || option}
                  value={option.value || option.name || option}
                >
                  {option.label || option.name || option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    });
  };

  return {
    locationsFilterValues,
    setLocationsFilterValues,
    handleLocationsFilterClear,
    handleApplyLocationFilters,
    getCountriesData,
    getStatesData,
    getCitiesData,
    fetchLocationsData,
    backdrop,
    setBackdrop,
    locationsFilterConfig,
    renderLocationsFilters
  };
};
