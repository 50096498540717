import axios from 'axios';
const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  function (config) {
    config.headers['x-api-key'] = process.env.REACT_APP_ADMIN_API_KEY;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function login() {
  return axios.get(BASE_API_URL + '/v2/admin/token');
}

function logout() {
  return axios.get(BASE_API_URL + `/api/logout`);
}

export default {
  login,
  logout
};
