import { useState, useEffect } from 'react';

// Export columns, default filters, and manageColumns as constants
export const addressColumns = [
  { id: 'id', label: 'Id' },
  { id: 'address', label: 'Address' },
  { id: 'version', label: 'Version' },
  { id: 'zipcode', label: 'Zip Code' },
  { id: 'stateId', label: 'State Id' },
  { id: 'isActive', label: 'Active' },
  { id: 'type', label: 'Type' },
  { id: 'cityId', label: 'City Id' },
  { id: 'countryId', label: 'Country Id' },
  { id: 'actions', label: 'Actions' }
];

export const defaultFilters = {
  city: '',
  state: '',
  country: '',
  is_active: true,
  zipcode: ''
};

export const addressManageColumns = {
  id: true,
  address: true,
  version: true,
  zipcode: true,
  stateId: true,
  cityId: false,
  locode: false,
  countryId: false,
  type: false
};

// Export the logic and state hooks for use in other components
export const Addresses = () => {
  const [addressesFilterValues, setAddressesFilterValues] = useState(defaultFilters);
  const [backdrop, setBackdrop] = useState(false);

  // Function to apply the filters
  const handleApplyAddressFilters = async query => {
    return await fetchAddressesData(query);
  };

  // Function to fetch location data
  const fetchAddressesData = async query => {
    let output = {
      data: [],
      'x-total-count': 0
    };
    return output;
  };

  const addressesFilterConfig = [];

  const renderAddressesFilters = () => {
    return addressesFilterConfig.map(filter => {
      const options = filter.options;

      return (
        <Grid item xs={4} key={filter.name}>
          <FormControl fullWidth>
            <InputLabel>{filter.label}</InputLabel>
            <Select
              name={filter.name}
              value={addressesFilterValues[filter.name]}
              onChange={filter.filterChange}
            >
              {options.map(option => (
                <MenuItem
                  key={option.value || option.name || option}
                  value={option.value || option.name || option}
                >
                  {option.label || option.name || option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    });
  };

  const handleAddressessFilterClear = () => {
    setAddressesFilterValues(defaultFilters);
  };

  return {
    addressesFilterValues,
    setAddressesFilterValues,
    handleAddressessFilterClear,
    handleApplyAddressFilters,
    fetchAddressesData,
    backdrop,
    setBackdrop,
    addressesFilterConfig,
    renderAddressesFilters
  };
};
